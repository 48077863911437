import React, { useState, useContext, useEffect } from 'react';
import app from '../firebase';
import { AuthContext } from '../Auth';

const Home = () => {
  // Context
  const { currentUser } = useContext(AuthContext);
  // Database
  const db = app.firestore();

  // Ref
  const ref = db.collection('management-data');

  // State
  const [data, setData] = useState({
    ditchACurrent: '',
    ditchAForcast: '',
    ditchHCurrent: '',
    ditchHForcast: '',
    gravityFlow: null,
    pump01: null,
    rain24HrTotal: parseFloat(0),
    currentPrecipitation: null,
    message: '',
  });

  const [tooltip, setTooltip] = useState(false);

  // Get Latest Message on Component Load
  const msgRef = db.collection('management-data');
  useEffect(() => {
    // Get Message
    msgRef
      .orderBy('timeDate', 'desc')
      .limit(1)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // Add message to state
          setData({ ...data, message: doc.data().message });
        });
      });
  }, []);

  const {
    ditchACurrent,
    ditchAForcast,
    ditchHCurrent,
    ditchHForcast,
    rain24HrTotal,
    currentPrecipitation,
  } = data;

  // On Change
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleSelectChange = (e) => {
    let v = e.target.value;

    if (v === 'true') {
      v = true;
    } else if (v === 'false') {
      v = false;
    }
    setData({ ...data, [e.target.name]: v });
  };

  // On Submit
  const onSubmit = (e) => {
    e.preventDefault();

    ref
      .doc()
      .set({
        ...data,
        timeDate: new Date(),
        user: currentUser.email,
      })
      .then(() => {
        console.log('Document added!');
      })
      .catch((error) => {
        console.error(error);
      });

    setData({
      ditchACurrent: '',
      ditchAForcast: '',
      ditchHCurrent: '',
      ditchHForcast: '',
      gravityFlow: '',
      pump01: '',
      rain24HrTotal: 0,
      currentPrecipitation: '',
    });
  };

  // TODO: Option to toggle foracast from management
  return (
    <div className='box'>
      <form onSubmit={onSubmit}>
        <button
          type='button'
          className='button is-small has-background-success'
          onClick={() => setTooltip(!tooltip)}
        >
          <span className='icon is-small has-text-dark'>
            <i className='fas fa-question' />
          </span>
        </button>
        <h1 className='title has-text-centered'>Manual Data</h1>

        {/* First Row */}
        <div className='columns'>
          <div className='field column'>
            <label htmlFor='ditchHCurrent' className='label'>
              Ditch H Current
              <div className='control'>
                <input
                  type='text'
                  name='ditchHCurrent'
                  onChange={handleChange}
                  value={ditchHCurrent}
                  className='input'
                  required
                  placeholder='e.g. 56'
                />
              </div>
              {tooltip ? (
                <p className='is-description'>
                  This field alters Ditch H Current values on the graphic.
                  Values below 41 & above 80 will not show.
                </p>
              ) : null}
            </label>
          </div>
          <div className='field column'>
            <label htmlFor='ditchHForcast' className='label'>
              Ditch H Forecast
              <div className='control'>
                <input
                  type='text'
                  name='ditchHForcast'
                  onChange={handleChange}
                  value={ditchHForcast}
                  className='input'
                  required
                  placeholder='e.g. 56'
                />
              </div>
              {tooltip ? (
                <p className='is-description'>
                  This field alters Ditch H Forecast values on the graphic.
                  Values below 41 & above 80 will not show.
                </p>
              ) : null}
            </label>
          </div>
          <div className='field column'>
            <label htmlFor='ditchACurrent' className='label'>
              Ditch A Current
              <div className='control'>
                <input
                  type='text'
                  name='ditchACurrent'
                  onChange={handleChange}
                  value={ditchACurrent}
                  className='input'
                  required
                  placeholder='e.g. 56'
                />
              </div>
              {tooltip ? (
                <p className='is-description'>
                  This field alters Ditch A Current values on the graphic.
                  Values below 41 & above 80 will not show.
                </p>
              ) : null}
            </label>
          </div>
          <div className='field column'>
            <label htmlFor='ditchAForcast' className='label'>
              Ditch A Forecast
              <div className='control'>
                <input
                  type='text'
                  name='ditchAForcast'
                  onChange={handleChange}
                  value={ditchAForcast}
                  className='input'
                  required
                  placeholder='e.g. 56'
                />
              </div>
              {tooltip ? (
                <p className='is-description'>
                  This field alters Ditch A Forecast values on the graphic.
                  Values below 41 & above 80 will not show.
                </p>
              ) : null}
            </label>
          </div>

          <div className='field column'>
            <label htmlFor='rain24HrTotal' className='label'>
              24hr Rain Total
              <div className='control'>
                <input
                  type='text'
                  name='rain24HrTotal'
                  onChange={handleChange}
                  value={rain24HrTotal}
                  className='input'
                  required
                  placeholder='e.g. 56'
                  max='24'
                />

                {tooltip ? (
                  <p className='is-description'>
                    This field alters the 24hr rain total values on the graphic.
                    Values above 24 will not show. This value also affect the
                    opacity of the clouds.
                  </p>
                ) : null}
              </div>
            </label>
          </div>
        </div>
        {/* Second Row */}
        <div className='columns'>
          <div className='field column is-centered'>
            <label htmlFor='pump-status01' className='label'>
              Pump Status
              <div className='control'>
                <div className='select is-multiple'>
                  <select
                    name='pump01'
                    className='browser-default'
                    onChange={handleSelectChange}
                    value={data.pump01}
                  >
                    <option value='' selected disabled hidden>
                      e.g. On/Off
                    </option>
                    <option value='True'>On</option>
                    <option value='False'>Off</option>
                  </select>
                </div>
              </div>
              {tooltip ? (
                <p className='is-description'>
                  This field sets the status of the pump station on the graphic.
                </p>
              ) : null}
            </label>
          </div>
          <div className='field column is-centered'>
            <label htmlFor='gravity-flow' className='label'>
              Gravity Flow
              <div className='control'>
                <div className='select is-multiple'>
                  <select
                    name='gravityFlow'
                    onChange={handleSelectChange}
                    value={data.gravityFlow}
                  >
                    <option value='' selected disabled hidden>
                      e.g. Opened/Closed
                    </option>
                    <option value='false'>Opened</option>
                    <option value='true'>Closed</option>
                  </select>
                </div>
              </div>
              {tooltip ? (
                <p className='is-description'>
                  This field sets the status of gravity flow. In automatic if
                  Ditch H Current is lower than 48 or Ditch A is greater than
                  Ditch H then gravity flow is open. In manual this field
                  overrides all gate logic.
                </p>
              ) : null}
            </label>
          </div>

          {/* FIXME: Medium screen cutoff is wonky */}
          <div className='field column is-centered'>
            <label htmlFor='rain-status' className='label'>
              Current Precipitation
              <div className='control'>
                <div className='select is-multiple'>
                  <select
                    name='currentPrecipitation'
                    onChange={handleSelectChange}
                    value={currentPrecipitation}
                  >
                    <option value='' selected disabled hidden>
                      e.g. Raining/Not Raining
                    </option>
                    <option value='true'>Raining</option>
                    <option value='false'>Not Raining</option>
                  </select>
                </div>
              </div>
              {tooltip ? (
                <p className='is-description'>
                  This field toggles the rain drops and storm sewer pipes.
                </p>
              ) : null}
            </label>
          </div>
        </div>

        <button type='submit' className='button is-success'>
          Submit
        </button>
      </form>
    </div>
  );
};

export default Home;
