import React from 'react';
import app from '../firebase';

const SignOut = () => {
  return (
    <button
      onClick={() => app.auth().signOut()}
      className='button is-danger'
      type='button'
    >
      Sign Out
    </button>
  );
};

export default SignOut;
