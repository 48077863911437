import React, { useContext } from 'react';
import SignOut from './SignOut';
import { AuthContext } from '../Auth';

const Nav = () => {
  // Auth Context
  const { currentUser } = useContext(AuthContext);

  return (
    <nav className='navbar' role='navigation' aria-label='main navigation'>
      <div id='navbarBasicExample' className='navbar-menu'>
        <div className='navbar-end'>
          <div className='navbar-item'>
            <p>
              <strong>Signed In As:</strong> {currentUser.email}
            </p>
          </div>
          <div className='navbar-item'>
            <div className='buttons'>
              <SignOut />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
