import React, { useCallback, useContext } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import app from './firebase';
import { AuthContext } from './Auth';

const Login = ({ history }) => {
  const handleLogin = useCallback(
    async event => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      try {
        await app
          .auth()
          .signInWithEmailAndPassword(email.value, password.value);
        history.push('/');
      } catch (error) {
        alert(error);
      }
    },
    [history]
  );

  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Redirect to='/' />;
  }

  return (
    <section className='hero is-fullehight header-image'>
      <div className='hero-body bg-image'>
        <div className='container'>
          <div className='columns is-centered'>
            <div className='column is-5-tablet is-4-desktop is-3-widescreen'>
              <form onSubmit={handleLogin} className='box'>
                {' '}
                <h1 className='is-size-3 has-text-centered'>Please Sign In</h1>
                <div className='field'>
                  <label className='label'>Email</label>

                  <div className='control has-icons-left'>
                    <input
                      name='email'
                      type='email'
                      placeholder='Email'
                      className='input'
                      required
                    />
                    <span className='icon is-small is-left'>
                      <i className='fa fa-envelope' />
                    </span>
                  </div>
                </div>
                <div className='field'>
                  <label className='label'>Password</label>
                  <div className='control has-icons-left'>
                    <input
                      name='password'
                      type='password'
                      placeholder='Password'
                      className='input'
                      required
                    />
                    <span className='icon is-small is-left'>
                      <i className='fa fa-lock' />
                    </span>
                  </div>
                </div>
                <div className='field'>
                  <button type='submit' className='button is-success'>
                    Log in
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withRouter(Login);
