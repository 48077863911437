import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import '../node_modules/bulma/css/bulma.min.css';
import './App.css';
import Home from './Home';
import Login from './Login';
import { AuthProvider } from './Auth';
import PrivateRoute from './PrivateRoute';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <div className='App'>
          <PrivateRoute exact path='/' component={Home} />
          <Route exact path='/login' component={Login} />
        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;
