import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

// const config = {
//   apiKey: process.env.REACT_APP_FIREBASE_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
//   databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID
// };

const config = {
  apiKey: 'AIzaSyDEmHhZnICgPQ4olokLe2AAiSq2Pv1tqAE',
  authDomain: 'pump-database.firebaseapp.com',
  databaseURL: 'https://pump-database.firebaseio.com',
  projectId: 'pump-database',
  storageBucket: 'pump-database.appspot.com',
  messagingSenderId: '176739365409',
  appId: '1:176739365409:web:e89d9538439c8ca9'
};

const app = firebase.initializeApp(config);

export default app;
