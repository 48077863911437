import React, { useState, useEffect } from 'react';
import moment from 'moment';
import app from '../firebase';

const CurrentValues = () => {
  // Database
  const db = app.firestore();
  // Ref
  const ref = db.collection('public-data');

  // State
  const [currentData, setCurrentData] = useState({
    message: '',
    ditchACurrent: '',
    ditchAForcast: '',
    ditchHCurrent: '',
    ditchHForcast: '',
    gravityFlow: null,
    pumpStatus: null,
    rainTotal: '',
    rainStatus: '',
  });

  // On Load
  useEffect(() => {
    ref
      .orderBy('timeDate', 'desc')
      .limit(1)
      .onSnapshot(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          const latestData = doc.data();

          for (let key in latestData) {
            if (/^pump/.test(key)) {
              if (latestData[key] === 'True') {
                console.log('here');
                setCurrentData({ ...latestData, pumpStatus: true });
                break;
              } else {
                setCurrentData(latestData);
              }
            }
          }
        });
      });

    // eslint-disable-next-line
  }, []);

  const {
    message,
    ditchACurrent,
    ditchAForcast,
    ditchHCurrent,
    ditchHForcast,
    pumpStatus,
    gravityFlow,
    rain24HrTotal,
    currentPrecipitation,
    timeDate,
  } = currentData;

  return (
    <div className="box">
      <p className="title has-text-centered">Current Automatic Values</p>
      <p>
        <strong>Time & Date: </strong>
        {timeDate ? moment.unix(timeDate.seconds).format('LLL') : null}
      </p>
      <p>
        <strong>Ditch H Current: </strong>
        {Number.parseFloat(ditchHCurrent).toFixed(4)}
      </p>
      <p>
        <strong>Ditch H Forecast: </strong>
        {ditchHForcast}
      </p>
      <p>
        <strong>Ditch A Current: </strong>
        {Number.parseFloat(ditchACurrent).toFixed(4)}
      </p>
      <p>
        <strong>Ditch A Forecast: </strong>
        {ditchAForcast}
      </p>
      <p>
        <strong>Pump Status: </strong>
        {!pumpStatus ? 'Off' : 'On'}
      </p>
      <p>
        <strong>Gravity Flow: </strong>
        {ditchHCurrent < 48 ? 'Opened' : gravityFlow}
      </p>
      <p>
        <strong>24hr Rain Total: </strong>
        {rain24HrTotal}
      </p>
      <p>
        {' '}
        <strong>Current Precipitation: </strong>
        {!currentPrecipitation ? 'Not Raining' : 'Raining'}
      </p>
      <p>
        <strong>Message:</strong>
      </p>
      <p>
        {!message ? (
          <span className="has-text-danger">No current message</span>
        ) : (
          message
        )}
      </p>
    </div>
  );
};

export default CurrentValues;
