import React, { useState, useEffect, useContext } from 'react';
import app from '../firebase';
import { AuthContext } from '../Auth';

const UpdateForm = () => {
  // Component State
  const [status, setStatus] = useState({
    update: null,
  });

  const [localStatus, setLocalStatus] = useState(false);

  // Auth Context
  const { currentUser } = useContext(AuthContext);

  // Database Referance
  const db = app.firestore();

  // Referance to the Update Document
  const updateRef = db.collection('management-data').doc('update-bool');

  // Referance to the Update Bool Log Collection
  const updateLogRef = db.collection('update-bool-log');

  // Attach a listener to it to update the status
  useEffect(() => {
    updateRef.get().then((doc) => {
      setStatus(doc.data());
    });
  }, []);

  // Deconstruct
  const { update } = status;

  // Handle Change
  const handleChange = (e) => {
    setLocalStatus(e.target.value);
  };

  // Handle Submit
  const onSubmit = (e) => {
    e.preventDefault();
    // Handles changing of Update Bool
    let v = localStatus;

    if (v === 'true') {
      v = true;
    } else if (v === 'false') {
      v = false;
    }

    updateRef.set({ update: v });

    // Handle Logging of Update Bool
    updateLogRef
      .doc()
      .set({
        setUpdateStatus: v === true ? 'automatic' : 'manual',
        timeDate: new Date(),
        user: currentUser.email,
      })
      .then(() => {
        console.log('Document added!');
      })
      .catch((error) => {
        console.error(error);
      });

    setStatus({ update: v });
  };

  return (
    <div className='container section'>
      <form className='box is-center' onSubmit={onSubmit}>
        <p className='is-size-3 has-text-centered'>
          Currently Updating From:
          <span
            className={
              update === false ? 'has-text-danger' : 'has-text-success'
            }
          >
            {' '}
            {update === false ? 'Manual Collection' : 'Automatic Collection'}
          </span>
        </p>
        <div className='columns'>
          <div className='field column'>
            <label htmlFor='updateStatus' className='label'>
              Update Status
              <div className='control'>
                <div className='select is-multiple'>
                  <select name='updateStatus' onChange={handleChange}>
                    <option value={false}>Update From Manual Collection</option>
                    <option value={true}>
                      Update From Automatic Collection
                    </option>
                  </select>{' '}
                  {/* TODO: Update the graphic link here */}
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href='http://www.fbclid2.com/emergency'
                  >
                    Live Graphic
                  </a>
                </div>{' '}
                <button
                  type='submit'
                  id='update-submit'
                  className='button is-success'
                >
                  Update
                </button>
              </div>
            </label>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UpdateForm;
