import React from 'react';
import UpdateForm from './components/UpdateForm';
import Values from './components/Values';
import CurrentValues from './components/CurrentValues';
import SignOut from './components/SignOut';
import Nav from './components/Nav';
import MessageForm from './components/MessageForm';

const Home = () => {
  console.log('v1');
  return (
    <>
      <Nav />
      <div className='hero-body padding-fix'>
        <UpdateForm />
        <div className='columns'>
          <div className='column is-half'>
            <Values />
          </div>
          <div className='column is-half'>
            <div className='columns'>
              <div className='column'>
                <CurrentValues />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
